import React, { useContext, useState, useEffect } from 'react'
import { IdbContext } from "../../../contexts";
import { Icon } from "../Icon";
import { Redirect } from 'react-router-dom';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import Workout from "../../../helpers/workoutParser/index";
import { ExerciseInput } from "../../Exercise";
import ResizeTextarea from "./ResizeTextarea";

import { useParsedWorkout, useOuterClick } from "../hooks";

// const defaultWorkout = `
// Bench press 45kg 5x5
// Squats 5f 45kg - 4f 40kg - 6f 35kg
// Bicep Curls 30kg, 10-10-8
// Plank 3x45s:15s
// Push ups 30-20-10
// Chin ups 6 - 4 - 3f
// Rowing 2000m 8mins 12s 533 ms // setting 6
// Sprints 5x100m:45s, 14.1s - 16.2s - 17.1s - 19.2s - 18.25s 
// `

export function WorkoutPresenter({id, workout, updateWorkout, routineData}) {


  // init routine
  let defaultRoutineId = null;
  let defaultRoutineName = "";
  if ( routineData ) defaultRoutineName = routineData.name;
  if ( workout.routineId ) defaultRoutineId = workout.routineId;


  const [currentRoutineId, setCurrentRoutineId] = useState(defaultRoutineId);
  const [currentRoutineName, setCurrentRoutineName] = useState(defaultRoutineName);
  const [newRoutineId, setNewRoutineId] = useState(null);


  const {   
    saveRoutine,
    routines,
    getRoutineByName,
  } = useContext(IdbContext);

  
  useEffect(()=>{
    getRoutineByName( currentRoutineName )
      .then(routine => {
        if ( routine ) {
          if ( currentRoutineId !== routine.id ) {
            // set current routine to pre-existing routine
            setCurrentRoutineId( routine.id );
          }
        } else if ( newRoutineId ) {
          // no pre-existing routine matches, so edit the new one created just below 
          setCurrentRoutineId(newRoutineId);
          saveRoutine({
            name: currentRoutineName,
            id: newRoutineId,
          });
        } else {
          // no pre-existing routine matches, so create one 
          saveRoutine({
            name: currentRoutineName,
          })
          .then( newRoutine  => {
            setNewRoutineId( newRoutine.id );
            setCurrentRoutineId( newRoutine.id );
          })
        }
      })
      .catch( console.error )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentRoutineName])

  useEffect(()=>{
    updateWorkout({routineId: currentRoutineId });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentRoutineId])

  const w = useParsedWorkout(workout.workoutText);
  return (
    <div className="page">
      <div className="workout">
        <div className="workout__header">
          <input 
            list="workoutlist" 
            className="workout__routine" 
            placeholder="Workout Title" 
            autoComplete="false"
            onChange={(e)=>setCurrentRoutineName(e.target.value)}
            value={currentRoutineName}
            />
          <datalist id="workoutlist">
            { routines.sort((a, b) => a.name.localeCompare(b.name)).map( routine => <option key={routine.id} value={routine.name} />)}
          </datalist>
          <Icon routineId={currentRoutineId} allowEdit={true}/>
        </div>
        <div className="workout__body">
          <ResizeTextarea
            className="workout__text" 
            onChange={(e)=>{
              updateWorkout({workoutText:e.target.value});
            }}
            value={workout.workoutText}
            placeholder="Your workout"
            />
          <div className="workout__display" >{
            w && w.getAllExercises().map((exercise, i, arr) => {
              let prev = null;
              if ( i - 1 >= 0 ) {
                prev = arr[i-1];
              }
              return (<ExerciseInput exercise={exercise} prev={prev}/>);
            })
          }</div>
        </div>

        <WorkoutFooter workout={workout} updateWorkout={updateWorkout} />

      </div>
    </div>
  )
}

export function WorkoutFooter({workout, updateWorkout}){
  const [dateIsOpen, setDateIsOpen] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);
  const [goTo, setGoTo] = useState(null);
  const footer = useOuterClick( ()=> setActionsOpen(false) );

  const now = new Date();
  const displayDate = workout.startAt || workout.createdAt || now;
  const dateOpts = { day:'numeric', month: 'long', hour: 'numeric', minute: '2-digit', hour12: true };
  if ( now.getUTCFullYear() !== displayDate.getUTCFullYear() ) {
    dateOpts.year = "numeric";
  }
  function deleteWorkout() {
    let isNowDeleted = !workout.deleted;
    updateWorkout({deleted:isNowDeleted}, false);
    if (isNowDeleted) setGoTo("/");
  }

  if( goTo ) return <Redirect to={goTo}/>
  return (
  <div ref={footer}>
    <div className={`workout__actions ${actionsOpen && "workout__actions--open"}`}>
      <button onClick={deleteWorkout} className="dangerButton">{workout.deleted ? "Restore" : "Delete"} this workout</button>
    </div>

    <div className="workout__footer">
      <button title="Change workout time" aria-label='Change workout time' className="workout__timebtn" onClick={()=>setDateIsOpen(true)}>
        {displayDate && displayDate.toLocaleString('default', dateOpts)}
      </button>
      <button className={`workout__actionbtn ${actionsOpen && "workout__actionbtn--open"}`} onClick={()=>setActionsOpen(!actionsOpen)}  title="Show more actions">
        ...
      </button>
    </div>

    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
          margin="normal"
          value={displayDate}
          open={dateIsOpen}
          onOpen={() => setDateIsOpen(true)}
          onClose={() => setDateIsOpen(false)}
          onChange={startAt => updateWorkout({startAt})}
          TextFieldComponent={() => null}
        />
      </MuiPickersUtilsProvider>
  </div>
  )
}

