import Exercise from "./Exercise";

export default class Workout {
  constructor( text ) {
    this.text = text;
    this.exercises = [];
    this.parse();
  }

  parse() {
    const lines = this.text.split("\n");
    this.exercises = lines.map( line => new Exercise( line ));
  }

  getExercises(){
    if ( !this.exercises ) return [];
    return this.exercises.filter( exercise => !exercise.blank );
  }

  getAllExercises() {
    return this.exercises;
  }

  getExercise(name) {
    if ( !this.exercises.length ) return null;
    const rxp = new RegExp("^" + name+"$","i");
    for ( let i = 0; i < this.exercises.length; i++ ) {
      if ( rxp.test(this.exercises[i].name.content)) return this.exercises[i];
    }
  }

  getTags(){
    let tags = [];
    this.exercises.forEach( exercise => {
      if ( exercise.tags.length > 0 ) {
        tags.push( ...exercise.tags );
      }
    })
    return tags;
  }
}

