import React from 'react'
import { Link } from "react-router-dom";
import { Icon } from "../Icon";

import "./Micro.scss";

export function Micro({workout, routine={}}) {
  const displayDate = workout.startAt || workout.createdAt;
  const dateOpts = { day:'numeric', month: 'short'};
  const now = new Date();
  if ( now.getUTCFullYear() !== displayDate.getUTCFullYear() ) {
    dateOpts.year = "numeric";
  }
  return (
    <Link to={`/history/${workout.id}`} className="micro-workout__item" key={workout.id}>
      <Icon routine={routine} className="micro-workout__icon"/>
      <span className="micro-workout__title">{routine.name || "Untitled"}</span>
      <span className="micro-workout__date">{displayDate && displayDate.toLocaleString('default', dateOpts)}</span>
    </Link>
    )
}

