import React, { useState, useEffect, createContext } from "react";

const InstallContext = createContext();

function InstallContextProvider( props ) {
  const [ shouldShowPrompt, setShouldShowPrompt ] = useState(false);
  const [ isOnline, setIsOnline ] = useState(navigator.onLine);
  const [ appInstalled, setAppInstalled ] = useState(false);
  const [ deferredPrompt, setDeferredPrompt ] = useState(null);

  function showInstallPrompt() {
    if ( deferredPrompt ) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice
        .then((result) => {
          setDeferredPrompt(null);
          setShouldShowPrompt( false );
        })
        .catch( err => {
          setShouldShowPrompt( false );
        })
    }
  }
  function updateOnlineStatus(event) {
    setIsOnline(navigator.onLine);
  }
  function dismissInstallPrompt() {
    setShouldShowPrompt( false );
  }

  useEffect(()=>{
    window.addEventListener('beforeinstallprompt',( event ) => {
      setDeferredPrompt( event );
      setShouldShowPrompt( true );
    })
    window.addEventListener('appinstalled', ( event ) => {
      setAppInstalled( true );
    })
    window.addEventListener('online',  updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  },[]);

  const defaultContext = {
    shouldShowPrompt,
    showInstallPrompt,
    dismissInstallPrompt,
    appInstalled,
    isOnline,
  };


  return (
    <InstallContext.Provider value={defaultContext}>
      { props.children }
    </InstallContext.Provider>
  );
}

export {
  InstallContext,
  InstallContextProvider,
}


