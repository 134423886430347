import React from "react";

export const BlockTypes = {
  letters: 0,
  numerical : 1,
  postnumerical: 2,
  idle: 3,
  special: 4,
  finished: 5,
  comment: 6,
  unknown: 7,
  tag: 8,
}
const invertedBlockTypes = {};
for ( const [k,v] of Object.entries(BlockTypes) ) {
  invertedBlockTypes[v] = k;
}
export class SyntaxBlock {
  constructor(){
    this.getClassName = this.getClassName.bind( this );
    this.setClassName = this.setClassName.bind( this );
    this.getTitle = this.getTitle.bind( this );
    this.useComponent = this.useComponent.bind( this );
  }
  getClassName(){
    if ( this.className ) {
      return this.className;
    } else if ( this.isQuantity ) {
      return this.blockType;
    } else {
      return invertedBlockTypes[this.blockType]
    }
  }
  setClassName(cname){
    this.className = cname;
  }
  appendClassName(cname){
    if ( !this.className ) {
      this.className = this.getClassName();
    }
    this.className += " ";
    this.className += cname;
  }
  getTitle(){
    if ( this.className ) {
      return this.className;
    } else if ( this.isQuantity ) {
      return `${this}`;
    } else {
      return invertedBlockTypes[this.blockType]
    }
  }
  useComponent( spaces ){
    return <><span title={this.getTitle()} className={this.getClassName()}>{this.raw}</span>{spaces}</>
  }
}

export default class Block extends SyntaxBlock {
  constructor( text, state, { startChar, endChar} ) {
    super();
    this.content = text.trim();
    this.raw = text;
    this.blockType = state;
    this.startChar = startChar;
    this.endChar = endChar;
    const parseAttempt = this.content - 0;
    if ( !Number.isNaN(parseAttempt )) {
      this.number = parseAttempt;
    }
  }

  toString() {
    return this.content;
  }
  isType( name ) {
    return "Block" === name;
  }
}

