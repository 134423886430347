import React, { useState, useEffect } from 'react'
// import { IdbContext } from "../../../contexts";
import { Error, Loading } from "../../";
import { WorkoutPresenter } from "./WorkoutPresenter";
import { useWorkout, useRoutine } from "../hooks";
import "./ShowWorkout.scss";


const states = {
  loading: 0,
  error: 1,
  loaded: 2,
}


export function Single(props) {
  const rawWorkoutId  = props.match.params.workoutId;
  let workoutId;
  if ( rawWorkoutId ) {
    workoutId = rawWorkoutId - 0; 
  }
  const [workout, updateWorkout] = useWorkout(workoutId);

  const [currentState, setCurrentState] = useState(states.loading);

  useEffect(()=>{
    if( typeof workout !== "undefined" ) {
      setCurrentState(states.loaded)
    }
  },[workout]);
  
  if ( currentState === states.error ) return <Error />
  if ( currentState === states.loading ) return <Loading />

  return <SingleRoutine id={workoutId} workout={workout} updateWorkout={updateWorkout} />;
}

export function SingleRoutine({workoutId, workout, updateWorkout}) {
  const [currentState, setCurrentState] = useState(states.loading);
  const [routine] = useRoutine(workout.routineId);
  console.log( workout );
  useEffect(()=>{
    if( typeof routine !== "undefined" || !Number.isInteger(workout.routineId) ) {
      setCurrentState(states.loaded)
    }
  },[routine, workout.routineId]);

  if ( currentState === states.error ) return <Error />
  if ( currentState === states.loading ) return <Loading />
  return <WorkoutPresenter id={workoutId} workout={workout} updateWorkout={updateWorkout} routineData={routine} />;
}