import { SyntaxBlock } from "../Block";

export default class Quantity extends SyntaxBlock {
  constructor( passedValue, passedUnit ) {
    super();
    let valueText = passedValue.number;
    let unitText = passedUnit.content;
    this.startChar = passedValue.startChar;
    this.endChar = passedUnit.endChar;
    this.passedValue = passedValue;
    this.passedUnit = passedUnit;
    this.unit = null;
    this.value = null;
    this.valueText = valueText;
    this.unitText = unitText;
    this.blockType = "Quantity";
    this.isQuantity = true;

    this.raw = passedValue.raw;
    this.raw += " ".repeat( passedUnit.startChar - passedValue.endChar - 1);
    this.raw += passedUnit.raw;
  }

  parse() {
    this.parseUnits();
    this.parseQuantity();

    this.isValid = this.isValidUnit && this.isValidQuantity;
  }

  isType( name ) {
    return this.blockType === name;
  }

  toString() {
    if ( this.isValid ) {
      return this.value + " " + this.unit;
    } else {
      return "";
    }
  }
  units = {}
  add ( quantity ) {
    if ( quantity.blockType === this.blockType && quantity.unit === this.unit ) {
      this.value += quantity.value;
      this.raw += " ".repeat( quantity.startChar - this.endChar - 1 );
      this.raw += quantity.raw;
      this.endChar = quantity.endChar;
    }
  }

  parseUnits() {
    if ( !this.units ) throw new Error("Type does not have units!");
    if ( !this.unitText ) throw new Error("No unit text!");
    if ( typeof this.unitText !== "string" ) throw new Error("Unit text must be a string!");
    this.unitText = this.unitText.toLowerCase();
    const validUnits = Object.keys( this.units );
    this.isValidUnit = false;
    validUnits.forEach( validUnit => {
      const aliases = this.units[validUnit];
      aliases.forEach( alias => {
        if ( alias === this.unitText ) {
          this.isValidUnit = true;
          this.unit = validUnit;
        }
      } )
    });
    return this.isValidUnit;
  }

  parseQuantity() {
    this.isValidQuantity = false;
    if ( typeof this.valueText === "number" ) {
      this.value = this.valueText;
      this.isValidQuantity = true;
    } else if ( typeof this.valueText === "string" ) {
      try {
        const toNumber = this.valueText - 0;
        if ( !Number.isNaN( toNumber ) ) {
          this.isValidQuantity = true;
          this.value = toNumber;
        }
      } catch( err ) {}
    }
    return this.isValidQuantity;
  }
}