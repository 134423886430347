import React, { useContext, useState, useEffect } from 'react'
import { IdbContext } from "../../contexts";
import { Link, NavLink, Route, Switch } from "react-router-dom";
import { Micro } from "../Workout";
import { Icon } from "../Workout";
import { HistoryIcon, InsightsIcon } from "./NavIcons";
import { Insights } from "./Insights";

import "./ListWorkouts.scss";

export function Home() {
  const [loading, setLoading] = useState(true);
  const [showCreateTray, setShowCreateTray] = useState(false);

  const { 
    routines,
    purgeOrphanedRoutines,
  } = useContext(IdbContext);

  async function toggleCreateTray(forceOverride) {
    if ( !showCreateTray ) {
      await purgeOrphanedRoutines()
        .then( wOuts => {
          setLoading(false);
        })
    }
    if ( typeof forceOverride === "boolean" ) return setShowCreateTray(forceOverride);
    return setShowCreateTray(!showCreateTray);
  }

  useEffect(()=>{
    purgeOrphanedRoutines()
      .then( wOuts => {
        setLoading(false);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  let createTrayClass ="create-tray";
  if ( showCreateTray ) createTrayClass += " create-tray--open";

  return (
    <div className="page">
      <Switch>
        <Route path="/insights" ><Insights loading={loading}/></Route>
        <Route path="/" exact ><ListWorkouts loading={loading}/></Route>
      </Switch>

      <div className="workouts-footer__wrapper">

        <div className="workouts-footer">
          <NavLink exact className="workouts-footer__navlink workouts-footer__navlink--list" activeClassName='workouts-footer__navlink--active' to="/">
            <HistoryIcon />
            History
          </NavLink> 
          <NavLink className="workouts-footer__navlink workouts-footer__navlink--insights" activeClassName='workouts-footer__navlink--active' to="/insights">
            <InsightsIcon />
            Insights
          </NavLink>
        </div>

        <div className={createTrayClass}>
          <button className="create-tray__toggle" onClick={()=>toggleCreateTray()}>
            <span className="visually-hidden">Show create options</span>
          </button>
          <div className="create-tray__body">
            <h3 className="create-tray__title">Record Workout</h3>
            {routines.filter(routine => !!routine.name && !!routine.name.trim().length ).map(routine => {
              return <Link to={`/new/${routine.id}`} className="create-tray__link"><Icon routineId={routine.id} className="create-tray__icon" />{routine.name}</Link>
            })}
            <Link to="/new" className="create-tray__link"><Icon className="create-tray__icon" />New Routine</Link>
          </div>
        </div>

      </div>
    </div>
  )
}

export function ListWorkouts({loading}) {
  // Todo: Virtualise list
  const { 
    workouts, 
    routines,
  } = useContext(IdbContext);

  const indexedRoutines = {};
  routines.forEach( routine => { indexedRoutines[routine.id] = routine; })

  return (
    <div className="workouts">
      <h1 className="page__title">Workouts</h1>
      { workouts.length === 0 && !loading ? 
        <div>
          <h3>You don't have any recorded workouts.</h3>
          <p>Try <Link to={`/profile`}>logging in</Link>to sync from another device, or record a new workout below.</p>
        </div>
      : false }
      {workouts.map( (workout) => <Micro key={workout.id} workout={workout} routine={indexedRoutines[workout.routineId]}/> )}
    </div>
  )
}
