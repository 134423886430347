import React, { useState, useEffect, createContext } from "react";
import { openDB } from "idb";

const IdbContext = createContext();
const idbName = "Workouts";
const idbVersion = 3;


function getWorkoutDb() {
  return openDB(idbName, idbVersion, {
    upgrade(db, oldVersion, newVersion, transaction) {
      var workoutStore;
      var routineStore;
      // Create an object store named notes. Object stores
      // in databases are where data are stored.
      if ( !db.objectStoreNames.contains("workouts") ) {
        workoutStore = db.createObjectStore('workouts', {keypath: "id", autoIncrement: true});
      } else {
        workoutStore = transaction.objectStore("workouts");
      }
      if ( !db.objectStoreNames.contains("routines") ) {
        routineStore = db.createObjectStore('routines', {keypath: "id", autoIncrement: true});
      } else {
        routineStore = transaction.objectStore("routines");
      }

      if (!workoutStore.indexNames.contains("syncIsRequired")) {
        workoutStore.createIndex("syncIsRequired", "syncRequired", { unique: false });
      } 
      if (!workoutStore.indexNames.contains("routineId")) {
        workoutStore.createIndex("routineId", "routineId", { unique: false });
      } 
      if (!routineStore.indexNames.contains("routineName")) {
        routineStore.createIndex("routineName", "name", { unique: true });
      } 
    },
    blocked() {
      // …
    },
    blocking() {
      // …
    },
    terminated() {
      // …
    },
  }).catch( ()=>{
    console.log("Could not get Workout Db")
  })
}

function IdbContextProvider( props ) {
  const [workouts,setWorkouts] = useState([]);
  const [routines,setRoutines] = useState([]);
  const [workoutsUpdatedAt, setWorkoutsUpdatedAt ] = useState(new Date());
  const [routinesUpdatedAt, setRoutinesUpdatedAt ] = useState(new Date());

  function saveNewWorkout( workoutData ) {
    const { 
      workoutText,
      startAt,
      endAt,
      routineId,
    } = workoutData;
    return getWorkoutDb()
      .then(db => {
        const toSave = {
          workoutText,
          createdAt: new Date(),
          updatedAt: new Date(),
          startAt: startAt || new Date(),
          endAt,
          syncRequired: true,
          rid:null,
          routineId,
          deleted: false,
        }
        return db.put("workouts", toSave )
          .then( createdKey => {
            toSave.id = createdKey;
            return saveWorkout(toSave)
              .then( () => toSave );
          });
      })
  }

  function purgeOrphanedRoutines(){
    return getWorkouts()
      .then( () => {
        return getWorkoutDb().then(db => {
          return db.getAll("routines")
            .then( theRoutines => {
              return Promise.all(theRoutines.map(routine => {
                return db.getAllFromIndex("workouts","routineId",routine.id)
                  .then( results => {
                    console.log( results )
                    const notDeletedCount = results.filter(doc => !doc.deleted).length;
                    if ( notDeletedCount === 0 && !routine.deleted ) return saveRoutine({ ...routine, deleted: true });
                    if ( notDeletedCount > 0 && routine.deleted ) return saveRoutine({ ...routine, deleted: false });
                    return Promise.resolve();
                  })
              }))
            })
        })
      })
      .then( ()=> getWorkouts() );
  }

  function saveWorkout( workoutData ) {

    const { 
      id,
      workoutText,
      startAt,
      endAt,
      routineId,
      deleted,
    } = workoutData;

    if ( id ) {
      return getWorkout(id)
        .then((workout) => {
          const newWorkout = {
            id,
            workoutText,
            createdAt: workout.createdAt,
            updatedAt: new Date(),
            startAt,
            endAt,
            syncRequired: true,
            rid:workout.rid,
            routineId,
            deleted,
          };
          return getWorkoutDb().then(db => {
            return db.put("workouts", newWorkout, id )
              .then( () => {
                setWorkoutsUpdatedAt(new Date());
                return newWorkout;
              } );
          })
        })
        .catch( () => {
          saveNewWorkout( workoutData);
        })
    } else {
      return saveNewWorkout(workoutData);
    }
  }

  function saveNewRoutine( routineData ) {
    const {
      name,
      iconName,
    } = routineData;
    return getWorkoutDb()
    .then(db => {
        const toSave = {
          name,
          iconName,
          createdAt: new Date(),
          updatedAt: new Date(),
          syncRequired: true,
          deleted: false,
        }
        return db.put("routines", toSave )
          .then( createdKey => {
            toSave.id = createdKey;
            return saveRoutine(toSave)
              .then( () => toSave );
          });
      })
  }

  function saveRoutine( routineData ) {
    const { 
      name,
      id,
      iconName,
      deleted,
    } = routineData;

    if ( id ) {
      return getRoutine(id)
        .then((routine) => {
          const newRoutine = {
            id,
            name,
            iconName,
            createdAt: routine.createdAt,
            updatedAt: new Date(),
            syncRequired: true,
            deleted,
          };
          return getWorkoutDb().then(db => {
            return db.put("routines", newRoutine, id )
            .then( () => {
              setRoutinesUpdatedAt(new Date());
              return newRoutine;
            } );          
          })
        })
        .catch( () => {
          return saveNewRoutine( routineData);
        })
    } else {
      return saveNewRoutine(routineData);
    }
  }

  function getWorkout(id) {
    return getWorkoutDb().then(db => {
      return db.get("workouts",id)
    })
  }

  function getRoutine(id) {
    return getWorkoutDb().then(db => {
      return db.get("routines",id)
    })
  }

  function getWorkoutsByRoutine(id) {
    return getWorkouts()
      .then( () => getWorkoutDb() )
      .then(db => db.getAllFromIndex("workouts","routineId",id))
      .then( theWorkouts => theWorkouts.sort( mostRecentFirst ) );   
  }

  function getRoutineByName(name) {
    return getWorkoutDb().then(db => {
      return db.getFromIndex("routines","routineName",name);
    })
  }


  function getWorkouts() {
    return getWorkoutDb().then(db => {
      return Promise.all([db.getAll("workouts"), db.getAll("routines")])
        .then( ([theWorkouts, theRoutines]) => {
          setWorkouts( theWorkouts.filter(workout => !workout.deleted).sort( mostRecentFirst ) );
          setRoutines( theRoutines.filter(routine => !routine.deleted).sort( mostRecentFirst ) );
          return theWorkouts;
        });
    })
  }

function mostRecentFirst( a, b) {
  let aDate = a.startAt || a.createdAt;
  let bDate = b.startAt || b.createdAt;
  return bDate - aDate;
}

  function getMostRecentInRoutine( id ) {
    return getWorkoutsByRoutine(id)
      .then(theWorkouts => {
        console.log( theWorkouts )
        if ( !theWorkouts ) return null;
        const mostRecentOfRoutine = theWorkouts.filter(workout => !workout.deleted).sort(mostRecentFirst);
        if ( mostRecentOfRoutine.length > 0 ) return mostRecentOfRoutine[0];
        return null;
      })
  }

  useEffect(()=>{
    getWorkouts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const defaultContext = {
    saveWorkout,
    saveRoutine,
    getWorkout,
    getWorkouts,
    getWorkoutsByRoutine,
    getRoutine,
    getRoutineByName,
    getMostRecentInRoutine,
    purgeOrphanedRoutines,
    workoutsUpdatedAt,
    routinesUpdatedAt,
    workouts,
    routines,
  }

  return (
    <IdbContext.Provider value={defaultContext}>
      { props.children }
    </IdbContext.Provider>
  );
}

export {
  IdbContext,
  IdbContextProvider,
}


