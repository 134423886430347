import Quantity from "./Quantity";

export default class Any extends Quantity {
  constructor(valueText, unitText) {
    super( valueText, unitText );
    this.blockType = "Any";
    this.isCompressable = false;

    this.units = {}
    this.parse();
  }

  parseUnits() {
    if ( !this.unitText ) throw new Error("No unit text!");
    if ( typeof this.unitText !== "string" ) throw new Error("Unit text must be a string!");
    this.unitText = this.unitText.toLowerCase();
    this.unit = this.unitText;
    this.isValidUnit = true;
    
    return this.isValidUnit;
  }
}
