import Quantity from "./Quantity";

export default class Time extends Quantity {
  constructor(valueText, unitText) {
    super( valueText, unitText );
    this.blockType = "Time";
    this.isCompressable = true;
    this.units = {
      "ms": ["ms","msec","msecs", "milsec", "milsecs", "millisecond","milliseconds"],
      "s": ["s","sec","secs","second","seconds"],
      "min": ["min", "mins","minute","minutes"],
      "h": ["h","hr","hour", "hrs", "hours"]
    }

    this.parse();
    if ( this.isValid ) {
      this.parseTime();
    }
  }
  parseTime() {
    let ms = 0;
    if ( this.unit === "ms" ) {
      ms = this.value;
    } else if ( this.unit === "s" ) {
      ms = this.value * 1000;
    } else if ( this.unit === "min" ) { 
      ms = this.value * 1000 * 60;
    } else if ( this.unit === "h" ) {
      ms = this.value * 1000 * 60 * 60;
    }
    this.value = ms;
    this.unit = "ms";
  }

  toString() {
    const ms = this.value % 1000;
    const s = Math.floor(this.value / 1000) % 60;
    const min = Math.floor(this.value / (1000 * 60)) % 60;
    const hr = Math.floor(this.value / (1000 * 60 * 60));
    let returnVal = "";
    if ( hr > 0 ) {
      returnVal += `${hr}hr`;
    }
    if ( min > 0 ) {
      if ( returnVal ) returnVal += " : "
      returnVal += `${min}min`;
    }
    if ( s > 0 ) {
      if ( returnVal ) returnVal += " : "
      returnVal += `${s}sec`;
    }
    if ( ms > 0 ) {
      if ( returnVal ) returnVal += " : "
      returnVal += `${ms}ms`;
    }
    if ( !returnVal ) {
      returnVal = "0s";
    }
    return returnVal;
  }

}
