import React from 'react'
import "./exercise.scss";
import weight from "../../assets/images/weight.svg";
import time from "../../assets/images/time.svg";
import distance from "../../assets/images/distance.svg";

export function Quantity({quantity, unitExtension, defaultImg=true}) {
  if ( !quantity ) return false;
  const type = quantity.blockType;
  let amount = quantity.value;
  let units = quantity.unit;
  let imgSrc;
  switch (type) {
    case "Weight":
      imgSrc = weight;
      break;
    case "Time":
      imgSrc = time;
      units = null;
      amount = `${quantity}`;
      break;
    case "Distance":
      imgSrc = distance;
      break;
    case "OnOffQuantity":
      return <div className="onOff">
        <Quantity quantity={quantity.on} unitExtension="(Work)" />
        <Quantity quantity={quantity.off} unitExtension="(Rest)" />
      </div>
    default:
      break;
  }
  if ( unitExtension ) {
    if ( units ) {
      units = units + " " + unitExtension
    } else {
      units = unitExtension;
    }
  }
  return (
    <div className="quantity">
      { imgSrc ? <img className={`quantity__icon quantity__icon--${type}`} src={imgSrc} alt={type} /> : defaultImg && <span className="quantity__icon quantity__icon--disabled"></span> }
      <span className="quantity__amount">{amount}</span>
      <span className="quantity__units">{units}</span>
    </div>
  )
}

export function Set( {title, setContents} ) {
  return (
    <div className="set">
    <span className="set__title" >{title}</span>
    { setContents.toArray().map( quantity => <Quantity quantity={quantity} defaultImg={false}/>)}
    { setContents.toFailure ? <span className="set__tofailure" >To Failure</span> : false}
  </div>
  )
}

function renderBlock( block, i ) {
  if ( block.isQuantity ) return <Quantity quantity={block} />;
  if ( block.blockType === "DropSetGroup" ) {
    return block.sets.map( (setContents, setI) => <Set title={`Set ${setI+1}`} setContents={setContents}/>)
  } else if ( block.blockType === "SetGroup" ) {
    return <Set title={`${block.setCount.value} Sets`} setContents={block.setContents}/> 
  }
  return false;
}

export function Exercise({ exercise }) {
  if ( !exercise ) return false;
  return (
    <div className="exercise">
      <h3 className="exercise__title" >{exercise.name.content}</h3>

      <Quantity quantity={exercise.weight} />
      <Quantity quantity={exercise.distance} />
      <Quantity quantity={exercise.time} />
      {exercise.blocks.map( renderBlock )}
      {exercise.comment ? <p className="exercise__comment">{exercise.comment}</p> : false}
    </div>
  )
}

export const ExerciseInput = React.memo(function ExerciseInput({exercise, prev}){
  return exercise.getSyntaxRow(prev);
})

