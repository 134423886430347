/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Link } from "react-router-dom";
import "./nav.scss";

const activeModifier = "--active";
const baseClass = "nav__link";
const disabledModifier = "--disabled";

export class NavItem {
  constructor( path, text, {useAnchorTag=false, onClick=false}) {
    this.path = path;
    this.text = text;
    this.useAnchorTag = useAnchorTag
    this.onClick = onClick;
  }
  getClass(path) {
    let returnClass = baseClass;
    if ( !this.path ) {
      returnClass += ` ${baseClass + disabledModifier}`;
    } else if ( this.path === path ) {
      returnClass += ` ${baseClass + activeModifier}`;
    } 
    return returnClass;
  }
  getPath() {
    if ( !this.path ) {
      return "/"
    }
    return this.path;
  }
  getTitle() {
    if ( !this.path ) {
      return "Coming Soon";
    } 
    return this.text;
  }
  toComponent(pathname){
    if ( this.useAnchorTag ) {
      return (
        <a key={this.text} href={this.getPath()} title={this.getTitle()} className={this.getClass(pathname)}>
          {this.text}
        </a>   
      )
    }
    return (
      <Link key={this.text} to={this.getPath()} title={this.getTitle()} onClick={this.onClick} className={this.getClass(pathname)}>
        {this.text}
      </Link>
    )
  }
}