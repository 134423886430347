import Block, { BlockTypes } from "./Block";
import {Any, Reps, Weight,Time,Distance,Sets,Rest} from "./Quantities";
import React from "react";

export default class Exercise {

  constructor( text ) {
    this.rawtext = text;
    this.text = text.trim();
    this.name = "";
    this.blocks = [];
    this.sets = [];
    this.tags = [];
    this.superOptions = {
      NOSHOW: 1,
      SHOW: 2,
      START: 3,
      END: 4,
    }
    if ( this.text.length === 0 ) {
      this.blank = true;
      this.parseSuper();
    } else {
      this.parseText();
    }
  }

  parseText() {
    const chars = this.text.split("");
    this.blocks = [];
    const self = this;
    let currentBlock = "";
    const { numerical, postnumerical, letters, special, idle, comment, unknown, tag } = Exercise.parseState;
    let state = letters;
    let startChar = 0;
    const text = new RegExp("[a-zA-Z]");
    const hyphen = new RegExp("[-]");
    const sparators = new RegExp("[:/,]");
    const hashtag = new RegExp("#");
    const whiteSpace = new RegExp("\\s");
    const number = new RegExp("[0-9.]");

    chars.forEach( (char, i)=> {
      function goToState( newState, pushCurrent=true ) {
        if ( pushCurrent ) {
          const trimmedBlock = currentBlock.trim();
          if ( trimmedBlock ){
            self.blocks.push( new Block( currentBlock, state, {startChar: startChar, endChar: i} ) );
          }
        } 
        startChar = i + 1;
        currentBlock = char;
        state = newState;
      }
      if ( state === letters ) {
        if ( text.test(char) || whiteSpace.test(char) || hyphen.test(char) ) {
          currentBlock += char;
        } else if ( number.test( char ) ) {
          goToState(numerical);
        } else if ( sparators.test( char ) ) {
          goToState(special);
        } else if ( hashtag.test(char) ) {
          goToState(tag);
        } else {
          goToState(unknown);
        }
      } else if ( state === numerical ) {
        if ( number.test( char ) ) {
          currentBlock += char;
        } else if ( text.test( char ) ) {
          goToState(postnumerical);
        } else if ( sparators.test( char ) || hyphen.test(char) ) {
          goToState(special);
        } else if ( whiteSpace.test(char) ) {
          goToState(postnumerical);
        } else if ( hashtag.test(char) ) {
          goToState(tag);
        } else {
          goToState(unknown);
        }
      } else if ( state === postnumerical ) {
        if ( text.test( char ) || ( whiteSpace.test(char) && !currentBlock.trim()  ) ) {
          currentBlock += char
        } else if ( whiteSpace.test( char ) && !!currentBlock.trim() ) {
          goToState(idle);
        } else if ( number.test( char ) ) {
          goToState(numerical);
        } else if ( sparators.test( char ) || hyphen.test(char) ) {
          goToState(special);
        } else if ( hashtag.test(char) ) {
          goToState(tag);
        } else {
          goToState(unknown);
        }
      } else if ( state === idle ) {
        if ( text.test( char ) ) {
          goToState(letters, false );
        } else if ( number.test( char ) ) {
          goToState(numerical, false );
        } else if ( sparators.test( char ) || hyphen.test(char) ) {
          goToState(special, false);
        } else if ( hashtag.test(char) ) {
          goToState(tag, false);
        } else if ( whiteSpace.test( char ) ) {
          // stay
        } else {
          goToState( unknown, false );
        }
      } else if ( state === special ) {
        if ( text.test( char ) ) {
          goToState(letters);
        } else if ( number.test( char ) ) {
          goToState(numerical);
        } else if ( whiteSpace.test( char ) ) {
          goToState(idle);
        } else if ( hashtag.test(char) ) {
          goToState(tag);
        } else if ( sparators.test( char ) || hyphen.test(char) ) {
          currentBlock += char;
          if ( currentBlock === "//" ) {
            // goToState(comment, false);
            // currentBlock = "";
            state = comment;
          }
        } else {
          goToState( unknown );
        }
      } else if ( state === comment ) {
        currentBlock += char;
      } else if ( state === unknown ) {
        if ( text.test( char ) ) {
          goToState(letters);
        } else if ( number.test( char ) ) {
          goToState(numerical);
        } else if ( sparators.test( char ) || hyphen.test(char) ) {
          goToState(special);
        } else if ( hashtag.test(char) ) {
          goToState(tag);
        } else {
          currentBlock += char
        }
      } else if ( state === tag ) {
        if ( hashtag.test(char) ) {
          goToState(tag);
        } else if ( sparators.test( char ) || hyphen.test(char) ) {
          goToState(special);
        } else {
          currentBlock += char
        }
      }
    });
    if ( currentBlock && currentBlock.trim() ) {
      self.blocks.push( new Block( currentBlock, state, { startChar, endChar: chars.length } ) );
    } else if ( currentBlock && currentBlock.trim() && state === comment ) {
      self.comment = currentBlock;
    }
    this.parseBlocks();
  }

  replaceBlocks( blockReplacements={} ) {
    const newBlocks = [];
    const numBlocks = this.blocks.length;
    for ( let i = 0; i < numBlocks; i++) {
      if ( typeof blockReplacements[i] !== "undefined" ) {
        if ( blockReplacements[i] ) {
          if ( Array.isArray(blockReplacements[i])) {
            newBlocks.push( ...blockReplacements[i] );
          } else {
            newBlocks.push( blockReplacements[i] );
          }
        }
      } else { 
        newBlocks.push( this.blocks[i] );
      }
    }
    this.blocks = newBlocks;
  }

  parseQuantities(){
    const { postnumerical } = Exercise.parseState;
    const blockReplacements = {};
    let blockReplaced = false;
    this.blocks.forEach( (block, i ) => {
      if ( block.blockType === postnumerical && i > 0 ) { 
        const prev = this.blocks[i-1];
        const weight = new Weight( prev, block );
        let replace;
        if ( weight.isValid ) {
          replace = weight;
        }
        if ( !replace ) {
          const distance = new Distance( prev, block );
          if ( distance.isValid ) {
            replace = distance;
          } 
        }
        if ( !replace ) {
          const time = new Time( prev, block );
          if ( time.isValid ) {
            replace = time;
          }
        }
        if ( !replace ) {
          const reps = new Reps( prev, block );
          if ( reps.isValid ) {
            replace = reps;
          }
        }
        if ( !replace ) {
          const set = new Sets( prev, block);
          if ( set.isValid ) {
            replace = set;
          }
        }
        if ( !replace ) {
          const any = new Any( prev, block);
          if ( any.isValid ) {
            replace = any;
          }
        }
        if ( replace ) {
          blockReplacements[i] = replace;
          blockReplacements[i-1] = null;
          blockReplaced = true;
        }
      }
    });
    if ( blockReplaced ) {
      this.replaceBlocks(blockReplacements);
    }
  }

  parseReps(){
    const { numerical } = Exercise.parseState;
    const blockReplacements = {};
    let blockReplaced = false;
    this.blocks.forEach( (block, i ) => {
      if ( block.blockType === numerical ) { 
        const reps = new Reps( block, {content:"reps", endChar: block.endChar , raw:"" } );
        let replace;
        if ( reps.isValid ) {
          replace = reps;
        }
        if ( replace ) {
          blockReplacements[i] = replace;
          blockReplaced = true;
        }
      }
    });
    if ( blockReplaced ) {
      this.replaceBlocks(blockReplacements);
    }
  }

  parseBlocks() {
    const { letters, tag } = Exercise.parseState;
    const self = this;
    this.name = "";

    this.blocks.forEach( (block, i ) => {
      if ( i === 0 && block.content && block.blockType === letters ) {
        self.name = block;
        block.setClassName("exercise-name");
        // this.replaceBlocks({ 0: null })
      } else if ( block.blockType === tag ) {
        this.tags.push( block );
      }
    });
    this.parseQuantities();
    this.parseReps();
    this.compressQuantities();
    this.parseRest();
    this.parseSets();
    this.parseSuper();
  }

  parseSets(){
    let sets = [];
    let setCount = 0;
    let globals;
    let currentSetIndex = 0;
    let currentSet = new Map();
    let hyphen = "-";
    let comma = ",";
    let merged = new Map();
    this.blocks.forEach( ( block, i ) => {
      if ( block.isQuantity && !block.isRest ) { 
        if ( block.blockType === "Sets" && sets.length < block.value ) {
          setCount = block.value;
        } else if ( block.blockType === "Any" ) {
          currentSet.set( block.unit, block );
          merged.set( block.unit, block );
        } else {
          currentSet.set( block.blockType, block );
          merged.set( block.blockType, block );
        }
      } else if ( hyphen === block.content ) {
        sets.push( currentSet );
        currentSetIndex++;
        currentSet =  new Map();
      } else if ( comma === block.content && currentSetIndex === 0 && !globals ) {
        globals = currentSet;
        currentSet =  new Map();
      }
      if ( currentSet.size > 0 && i === this.blocks.length - 1 ) {
        sets.push( currentSet );
      }
    });
    sets.forEach( ( set,i ) => {
      merged.forEach( (quantity, quantityType) => {
        if ( !set.has(quantityType) ) set.set(quantityType, quantity);
      })
    })
    if ( setCount && setCount > sets.length && sets.length > 0 ) {
      const last = sets[ sets.length -1 ];
      const toCreate = setCount - sets.length;
      if ( last && toCreate > 0 ) {
        for(let i = 0; i < toCreate; i++) {
          sets.push(last)
        }
      }
    }
    this.availableUnits = [];
    merged.forEach( (quantity, quantityType) => {
      let combined;
      if ( quantityType === "Any") {
        combined = quantity.unit.charAt(0).toUpperCase() + quantity.unit.substr(1);
        quantityType = combined;
      } else if ( quantity.unit !== quantityType ) {
        combined = `${quantityType} (${quantity.unit})`;
      } else {
        combined = quantityType;
      }
      this.availableUnits.push( { type: quantityType, unit: quantity.unit, combined } );
    })

    this.sets = sets;    
  }

  compressQuantities() {
    const blockReplacements = {};
    let blockReplaced = false;
    let prevQuantity;
    this.blocks.forEach( (block, i ) => {
      if ( block.isQuantity ) {
        if ( prevQuantity && prevQuantity.blockType === block.blockType && prevQuantity.isCompressable ) {
          prevQuantity.add( block );
          blockReplacements[i] = null;
          blockReplaced = true;
        } else {
          prevQuantity = block;
        }
      } else {
        prevQuantity = null;
      }
    });

    if ( blockReplaced ) {
      this.replaceBlocks(blockReplacements);
    }
  }

  parseRest() {
    const { special } = Exercise.parseState;
    const numBlocks = this.blocks.length;
    const blockReplacements = {};
    let blockReplaced = false;

    this.blocks.forEach( (block, i ) => {
      if ( block.blockType === special && i > 0 && i < numBlocks - 1 && block.content === ":" ) {
        const prev = this.blocks[i-1];
        const next = this.blocks[i+1];
        if ( prev && prev.isQuantity && next && next.isQuantity ) {
          const rest = Rest( next );
          blockReplacements[i+1] = rest;
          blockReplaced = true;
        }
      }
    });

    if ( blockReplaced ) {
      this.replaceBlocks(blockReplacements);
    }
  }

  parseSuper(){
    this.super = this.superOptions.NOSHOW;
    if ( this.name && `${this.name}`.toLowerCase().trim() === "super" ) {
      this.super = this.superOptions.START;
    } else if ( this.name === "" ) {
      this.super = this.superOptions.END;
    }
    console.log( this );
  }
  setSuper(option) {
    this.super = option;
  }

  getSyntaxRow(prev){
    if ( prev && prev.super && this.super !== this.superOptions.END ) {
      if ( prev.super === this.superOptions.START || prev.super === this.superOptions.SHOW ) {
        this.setSuper(this.superOptions.SHOW);
      }
    }
    console.log( this, prev );
    console.log( "getting syntax row")
    let bonusclasses = " ";
    if ( this.super === this.superOptions.SHOW ) {
      bonusclasses += ' exerciserow--super '
    }
  return <>
    <span className={"exerciserow " + bonusclasses}>{this.blocks.map((block,i, arr) => {
      if ( i === this.blocks.length - 1 ) return block.useComponent();
      const next = this.blocks[i+1];
      const spaces = " ".repeat( next.startChar - block.endChar - 1 );
      return block.useComponent(spaces);
    })}</span>
    {"\n"}
  </>
  }

  toString(){
    return `Exercise: ${this.name}, ${this.blocks.join("|")}`
  }
}

Exercise.parseState = BlockTypes;