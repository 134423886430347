import React, { useEffect, useState, useRef } from "react";
import { useDebounce } from "../hooks";

export default function ResizableTextarea({minRows=10, onChange, placeholder, className, value})  {

  const [rows, setRows] = useState( minRows );
  const textarea = useRef();
  const onResize = useDebounce( resize, 200);
  function resize() {
    console.log("resizing")
    if ( !textarea.current ) return;
    const textareaLineHeight = 24;
  	textarea.current.rows = minRows; // reset number of rows in textarea 
    let currentRows = Math.ceil(textarea.current.scrollHeight / textareaLineHeight) + 1;
    if ( currentRows < minRows ) currentRows = minRows;
    textarea.current.rows = currentRows; // reset number of rows in textarea 
    if ( currentRows !== rows ) {
      setRows(currentRows)
    }
  }

	function handleChange(event) {
    if (onChange ) onChange(event);
  };

  useEffect(()=>{
    function handleResize(){ 
      if (onResize) onResize();
     }
    window.addEventListener("resize",handleResize);
    return ()=>{
      window.removeEventListener("resize",handleResize);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[onResize])
  
  useEffect(()=>{
    resize();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value])
	
  return (
    <textarea
      ref={textarea}
      rows={rows}
      value={value}
      placeholder={placeholder}
      className={className}
      onChange={handleChange}
    />
  );
}