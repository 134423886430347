/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom';
import { Loading } from "../../";
import { IdbContext } from "../../../contexts";

export function New(props) {
  const rawRoutineId  = props.match.params.routineId;
  const [ createdId, setCreatedId ] = useState(null);

  const { 
    saveWorkout,
    getMostRecentInRoutine,
  } = useContext(IdbContext);
  useEffect(()=>{
    let routineId;
    if ( rawRoutineId ) {
      routineId = rawRoutineId - 0; 
    }
    if ( routineId && !Number.isNaN(routineId) ) {
      // create new workout based on prev in routine and redirect
      getMostRecentInRoutine(routineId)
        .then( workout => {
          saveWorkout({ 
            workoutText: workout.workoutText,
            routineId: workout.routineId,
          })
          .then( (workout) => {
            setCreatedId( workout.id );
          })
        })
    } else {
      saveWorkout({ workoutText: "" })
        .then( (workout) => {
          setCreatedId( workout.id );
        })
    // create new blank workout and redirect
    }
    
  },[])
  if ( createdId === null ) return <Loading />
  return <Redirect to={`/history/${createdId}`}  />
}
