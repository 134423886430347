import React, { useContext, useState, useEffect } from "react";
import { IdbContext } from "../../contexts";
import Workout from "../../helpers/workoutParser/index";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import Chart from "react-apexcharts";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function normaliseExerciseName(name) {
  if ( !name ) return;
  if ( typeof name !== "string" ) return;
  const hyphen = new RegExp("[-]");
  const whiteSpace = new RegExp("\\s");
  let normalised = name.toLowerCase();
  normalised  = normalised.replace( hyphen, "");
  normalised  = normalised.replace( whiteSpace, "");
  return normalised ;
}

function uniqueLowerMerge(array1, array2) {
  let newArray = array1.map( el => el );
  let strArr = array1.map( el => JSON.stringify(el).toLowerCase());
  array2.forEach( el => {
    let strVersion = JSON.stringify(el).toLowerCase();
    if ( strArr.indexOf( strVersion ) < 0 ) {
      newArray.push( el );
      strArr.push(strVersion);
    } 
  })
  return newArray;
}

class NameReducer {
  constructor() {
    this.names = new Map();
  }
  push(name, availableUnits) {
    if ( !name ) return;
    if ( typeof name !== "string" ) return;
    let normalised = normaliseExerciseName(name);
    if ( normalised && !this.names.has(normalised) ) {
      this.names.set(normalised, {name: name, availableUnits: uniqueLowerMerge(availableUnits,[])});
    } else if ( normalised && this.names.has(normalised) ) {
      let current = this.names.get(normalised);
      this.names.set(normalised, {
        name: current.name,
        availableUnits: uniqueLowerMerge(current.availableUnits, availableUnits)
      })
    }

  }
  get( name ) {
    if ( !name ) return;
    if ( typeof name !== "string" ) return;
    let normalised = normaliseExerciseName(name);
    if ( normalised && this.names.has(normalised) ) return this.names.get(normalised);
    return null;
  }
  retrieve() {
    return Array.from(this.names).map( nameArray => nameArray[1]);
  }
  retrieveNames(){
    return Array.from(this.names).map( nameArray => nameArray[1].name);
  }
  retrieveUnits(){
    return Array.from(this.names).map( nameArray => nameArray[1].availableUnits);
  }
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function useGraphData({workouts, exercisename, unitname, type="total"}) {
  const [series, setSeries] = useState();
  const [annotations, setAnnotations] = useState();
  useEffect(()=>{
    if ( !workouts || !unitname || !exercisename ) return;
    let data = [];
    workouts.forEach( workout => {
      const exercise = workout.parsedData.getExercise(exercisename);
      if ( exercise ) {
        let value;
        if ( type === "total" || type === "cumulative") {
          value = exercise.sets.reduce((sum, current) => {
            const v = current.get(unitname.type)
            return v ? sum + v.value : sum;
          },0);
        } else if ( type === "max" ) {
          value = exercise.sets.reduce((max, current) => {
            const v = current.get(unitname.type)
             return v && v.value > max ? v.value : max;
            },0);
        }
        data.push( [
          workout.startAt,
          value,
        ])
      }
    });
    if ( type === "cumulative" ) {
      let i = 0;
      let currentTotal = 0;
      for ( i=1; i <= data.length; i++ ) {
        data[data.length - i][1] += currentTotal;
        currentTotal = data[data.length - i][1];
      }
    }
    setSeries([{name:exercisename + " " + unitname.type ,data}]);
  },[workouts, exercisename, unitname, type]);

  useEffect(()=>{
    let data = [];
    if ( !workouts ) return;
    workouts.forEach( workout => {
      const tags = workout.parsedData.getTags();
      if ( tags && tags.length > 0 ) {
        const labelText = tags.map(tag=>tag.content).join(", ");
        data.push(createAnnotation(workout.startAt,labelText));
      }
    })
    setAnnotations( data );
  },[workouts]);



  return {
    series,
    annotations,
  };
}

function createAnnotation(date, text) {
  const annotation = {
    x: date.getTime(),
    strokeDashArray: [10, 10],
    borderColor: '#EB782A',
    label: {
      borderColor: '#EB782A',
      style: {
        color: '#fff',
        background: '#EB782A',
      },
      text: text.replace("#",""),
    }
  }
  return annotation
}

function GraphExercise2({workouts, exercisename, unitname, type = "total" }) {
  const {series, annotations } = useGraphData({workouts, exercisename, unitname, type});
  const options = {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: []
          },
          autoSelected: 'zoom'
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 5,
      },
      title: {
        text: `${capitalizeFirstLetter(type)} ${unitname && unitname.type}: ${capitalizeFirstLetter(exercisename)}`,
        align: 'left'
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100]
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return (val).toFixed(0);
          },
        },
        tickAmount: 5,
        title: {
          text: (unitname && unitname.combined) || "",
        },
      },
      xaxis: {
        type: 'datetime',
      },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val) {
            return `${(val).toFixed(0)} ${unitname.unit === unitname.type ? "" : unitname.unit }`
          },
        }
      },
      annotations: {
        xaxis: annotations,
      }
  }
  
  if ( !series ) return false;
  return  <Chart
    options={options}
    series={series}
    type="area"
    height={300}
  />
}

const allowedGraphTypes = new Map([
  ["total", "Session Total"],
  ["max", "One-Set Max"],
  ["cumulative", "Cumulative Total"],
]);

export function Insights({loading}) {
  const [workouts, setWorkouts] = useState();
  const [routineId, setRoutineId] = useState("");
  const [exercisename, setExercisename] = useState("");
  const [unitname, setUnitname] = useState("");
  const [graphType, setGraphType] = useState("total");
  
  const [exerciseNames, setExerciseNames] = useState([]);
  const [unitNames, setUnitNames] = useState([]);

  const { 
    getWorkoutsByRoutine,
    routines,
  } = useContext(IdbContext);

  useEffect(()=>{
    if ( ! Number.isInteger(routineId) ) return;
    getWorkoutsByRoutine(routineId)
      .then( theWorkouts => theWorkouts.map( aWorkout => {
          aWorkout.parsedData = new Workout(aWorkout.workoutText);
          return aWorkout;
        }) 
      )
      .then( setWorkouts );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[routineId])

  useEffect(()=>{
    if ( Number.isInteger(routineId) && routineId >= 0 ) {
      getWorkoutsByRoutine(routineId)
        .then( theWorkouts => {
          if ( theWorkouts.length > 0 ) {
            const listOfExercises = new NameReducer();
            
            theWorkouts.forEach( (workout,i) => {
              const parsedWorkout = new Workout(workout.workoutText);
              parsedWorkout.exercises.forEach( exercise => {
                listOfExercises.push(exercise.name.content, exercise.availableUnits);
              })
            })
            setExerciseNames(listOfExercises.retrieveNames());
          }
        }) 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[routineId])

  useEffect(()=>{
    if ( exercisename && Number.isInteger(routineId) && routineId >= 0 ) {
      getWorkoutsByRoutine(routineId)
        .then( theWorkouts => {
          if ( theWorkouts.length > 0 ) {
            const listOfExercises = new NameReducer();
            
            theWorkouts.forEach( (workout,i) => {
              const parsedWorkout = new Workout(workout.workoutText);
              parsedWorkout.exercises.forEach( exercise => {
                listOfExercises.push(exercise.name.content, exercise.availableUnits);
              })
            })
            const exercise = listOfExercises.get(exercisename);
            if ( exercise ) {
              setUnitNames(exercise.availableUnits);
            }
          }
        }) 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[exercisename])

  const classes = useStyles();

  return (
    <div className="insights">
      <h1 className="page__title">Insights</h1>
      <FormControl className={classes.formControl}>
        <InputLabel>Routine</InputLabel>
        <Select
          value={routineId}
          onChange={(e)=>{setRoutineId(e.target.value); setExercisename(""); setUnitname(null)}}
        >
          <MenuItem value="">None</MenuItem>
          {routines.map(routine => <MenuItem value={routine.id} >{routine.name}</MenuItem>)}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel>Exercise</InputLabel>
        <Select
          value={exercisename}
          onChange={(e)=>{setExercisename(e.target.value); setUnitname(null);}}
        >
          <MenuItem value="">None</MenuItem>
          {exerciseNames.map(name => <MenuItem value={name} >{name}</MenuItem>)}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel>Units</InputLabel>
        <Select
          value={unitname}
          onChange={(e)=>setUnitname(e.target.value)}
        >
          <MenuItem value="">None</MenuItem>
          {unitNames.map(name => <MenuItem value={name} >{name.combined}</MenuItem>)}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel>Graph Type</InputLabel>
        <Select
          value={graphType}
          onChange={(e)=>setGraphType(e.target.value)}
        >
          {Array.from(allowedGraphTypes).map(([name, label]) => <MenuItem value={name} >{label}</MenuItem>)}
        </Select>
      </FormControl>
      
      <GraphExercise2 workouts={workouts} exercisename={exercisename} unitname={unitname} type={graphType} />
    </div>
  )
}


