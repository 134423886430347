import React from 'react'

export function HistoryIcon() {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title>History</title><defs><filter x="-2%" y="-13.1%" width="104.1%" height="131%" filterUnits="objectBoundingBox" id="a"><feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" in="shadowBlurOuter1"/></filter><path d="M320 526v42H0v-42h225a25 25 0 1050 0h45z" id="b"/></defs><g fill="none" fill-rule="evenodd"><g transform="translate(-37 -534)"><use fill="#FFF" xlinkHref="#b"/></g><g fill="currentColor"><path d="M0 7h4v7H0zM6 0h4v14H6zM12 2h4v12h-4z"/></g></g></svg>
  )
}

export function InsightsIcon() {
  return (
    <svg width="37" height="16" viewBox="0 0 37 16" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title>Insights</title><defs><filter x="-2%" y="-15.7%" width="104.1%" height="137.1%" filterUnits="objectBoundingBox" id="a"><feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" in="shadowBlurOuter1"/></filter><path d="M320 533v35H0v-35h225.02l-.02 1a25 25 0 1049.98-1H320z" id="b"/></defs><g fill="none" fill-rule="evenodd"><g transform="translate(-124 -535)"><use fill="#FFF" xlinkHref="#b"/></g><g transform="translate(1 1)" stroke="currentColor"><path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M2 12l9-7 11 4 11-7"/><circle fill="#FFF" cx="2" cy="12" r="2"/><circle fill="#FFF" cx="11" cy="5" r="2"/><circle fill="#FFF" cx="22" cy="9" r="2"/><circle fill="#FFF" cx="33" cy="2" r="2"/></g></g></svg>
  )
}

export function EyeIcon({strikeThrough=false}){
  return (
    <span className={`${strikeThrough ? "strikethrough" : ""}`}>
      <svg width="52" height="26" viewBox="0 0 52 26" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title>Eye</title><defs><path d="M0 10.5C14-3 34-3 48 10.5c-13 16-34 16-48 0z" id="a"/></defs><g fill="none" fill-rule="evenodd"><g transform="translate(2 2)"><mask id="b" fill="#fff"><use xlinkHref="#a"/></mask><use stroke="currentColor" stroke-width="3" stroke-linejoin="round" xlinkHref="#a"/><circle fill="currentColor" mask="url(#b)" cx="24" cy="8.5" r="10.5"/></g><path d="M24 5c-3 0-5 2.5-5 5" stroke="#FFF" stroke-width="2" stroke-linecap="round"/></g></svg>
    </span>
  )
}
