import Quantity from "./Quantity";

export default class Sets extends Quantity {
  constructor ( valueText, unitText ) {
    super( valueText, unitText );
    this.blockType = "Sets";

    this.units = {
      "Sets": ["x","set","sets"],
    }
    this.parse();
  }
}