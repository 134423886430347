import Quantity from "./Quantity";

export default class Distance extends Quantity {
  constructor(valueText, unitText) {
    super( valueText, unitText );
    this.blockType = "Distance";
    this.isCompressable = true;

    this.units = {
      "m": ["m", "meter", "meters"],
      "Km": ["km", "kmeter", "kmeters", "kilometer", "kilometers"],
    }
    this.parse();

  }
}
