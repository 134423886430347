import { useState, useContext, useEffect, useRef } from 'react'
import { IdbContext } from "../../contexts";
import Workout from "../../helpers/workoutParser/index";

function oneOf(a,b) {
  if ( typeof a !== "undefined" ) return a;
  return b;
}


export function useOuterClick( callback, ref ) {
  const newRef = useRef();
  let theRef;
  if ( ref ) {
    theRef = ref;
  } else {
    theRef = newRef;
  }
  useEffect(()=>{
    const handleClick = (e)=>{
      if (theRef.current && theRef.current.contains(e.target)){ return }
      callback(e);
    }
    document.addEventListener("mousedown",handleClick, false);
    return () => document.removeEventListener("mousedown",handleClick, false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return theRef;
}

export function useParsedWorkout( workoutText ) {
  const [parsedWorkout, setParsedWorkout] = useState();
  useEffect(()=>{
    setParsedWorkout( new Workout(workoutText) );
  },[workoutText]);
  return parsedWorkout;
}

export function useDebounce( fn, timeoutMs ) {
  const [ ReturnFn, setReturnFn ] = useState()
  useEffect(()=>{
    let timeout;
    setReturnFn( ()=> (...args) => {
      clearTimeout( timeout );
      timeout = setTimeout(()=>{
        fn(...args);
      }, timeoutMs)
    } )
    return ()=>{clearTimeout(timeout)}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return ReturnFn;
}

export function useWorkout(workoutId) {
  const { getWorkout, saveWorkout, workoutsUpdatedAt } = useContext(IdbContext);
  const [workout, setWorkout] = useState();
  const debounceSaveWorkout = useDebounce(saveWorkout, 600);
  useEffect( () => {
    if ( workoutId && !Number.isNaN(workoutId) ) {
      getWorkout(workoutId).then( (result)=>{
        if ( result ) return setWorkout( result );
        return setWorkout(null);
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workoutId,workoutsUpdatedAt]);
  const updateWorkout = (overrides={}, debounce=true) => {
    if ( !workout ) return;
    const newWorkout = {
      id: oneOf(overrides.id, workout.id),
      workoutText: oneOf(overrides.workoutText, workout.workoutText),
      routineId: oneOf(overrides.routineId, workout.routineId),
      startAt: oneOf(overrides.startAt, workout.startAt),
      deleted: oneOf(overrides.deleted, workout.deleted),
    }
    setWorkout( newWorkout );
    if ( !!debounceSaveWorkout && debounce ){
      debounceSaveWorkout( newWorkout )
    } else {
      saveWorkout( newWorkout );
    }
  }

  return [workout, updateWorkout];
}

export function useRoutine(routineId) {
  const { getRoutine, saveRoutine, routinesUpdatedAt } = useContext(IdbContext);
  const [routine, setRoutine] = useState();
  const debounceSaveRoutine = useDebounce(saveRoutine, 600);

  useEffect( () => {
    if ( routineId && !Number.isNaN(routineId) ) {
      getRoutine(routineId).then( (result)=>{
        if ( result ) return setRoutine( result );
        return setRoutine(null);
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routineId, routinesUpdatedAt]);
  const updateRoutine = (overrides={}, debounce=true) => {
    if ( !routine ) return;
    const newRoutine = {
      id: oneOf(overrides.id, routine.id),
      name: oneOf(overrides.name, routine.name),
      iconName: oneOf(overrides.iconName, routine.iconName),
    };
    setRoutine( newRoutine );
    if ( !!debounceSaveRoutine && debounce ) { 
      debounceSaveRoutine( newRoutine );
    } else {
      saveRoutine( newRoutine );
    }
  }
  return [routine, updateRoutine];
}