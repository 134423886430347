import React, { useState, useEffect, useContext } from 'react'
import { IdbContext } from "../../../contexts";
import "./icon.scss";

import water from "../../../assets/images/icons/001-water bottle.svg"
import sport from "../../../assets/images/icons/002-sport.svg"
import skipping from "../../../assets/images/icons/003-skipping rope.svg"
import buck from "../../../assets/images/icons/004-buck.svg"
import gymnastic from "../../../assets/images/icons/005-gymnastic rings.svg"
import speedball from "../../../assets/images/icons/006-speedball.svg"
import heart from "../../../assets/images/icons/007-heart rate.svg"
import pedometer from "../../../assets/images/icons/008-pedometer.svg"
import punching from "../../../assets/images/icons/009-punching bag.svg"
import smartwatch from "../../../assets/images/icons/010-smartwatch.svg"
import traffic from "../../../assets/images/icons/011-traffic cone.svg"
import sports from "../../../assets/images/icons/012-sports bra.svg"
import trainers from "../../../assets/images/icons/013-trainers.svg"
import dumbbell from "../../../assets/images/icons/014-dumbbell.svg"
import tank from "../../../assets/images/icons/015-tank top.svg"
import sportbag from "../../../assets/images/icons/016-sportbag.svg"
import yoga from "../../../assets/images/icons/017-yoga ball.svg"
import rope from "../../../assets/images/icons/018-rope.svg"
import stationary from "../../../assets/images/icons/019-stationary bike.svg"
import muscle from "../../../assets/images/icons/020-muscle.svg"
import measure from "../../../assets/images/icons/021-measure tape.svg"
import barbell from "../../../assets/images/icons/022-barbell.svg"
import hand from "../../../assets/images/icons/023-hand grip.svg"
import diet from "../../../assets/images/icons/024-diet.svg"
import training from "../../../assets/images/icons/025-training.svg"
import foam from "../../../assets/images/icons/026-foam roller.svg"
import plyo from "../../../assets/images/icons/027-plyo.svg"
import training2 from "../../../assets/images/icons/028-training.svg"
import agility from "../../../assets/images/icons/029-agility.svg"
import agility2 from "../../../assets/images/icons/030-agility.svg"
import calendar from "../../../assets/images/icons/031-calendar.svg"
import energy from "../../../assets/images/icons/032-energy drink.svg"
import location from "../../../assets/images/icons/033-location.svg"
import towel from "../../../assets/images/icons/034-towel.svg"
import step from "../../../assets/images/icons/035-step.svg"
import chest from "../../../assets/images/icons/036-chest expander.svg"
import resistance from "../../../assets/images/icons/037-resistance band.svg"
import boxing from "../../../assets/images/icons/038-boxing glove.svg"
import whistle from "../../../assets/images/icons/039-whistle.svg"
import treadmill from "../../../assets/images/icons/040-treadmill.svg"
import basketball from "../../../assets/images/icons/041-basketball.svg"
import swimming from "../../../assets/images/icons/042-swimming pool.svg"
import football from "../../../assets/images/icons/043-football ball.svg"
import stopwatch from "../../../assets/images/icons/044-stopwatch.svg"
import resistance2 from "../../../assets/images/icons/045-resistance band.svg"
import yoga2 from "../../../assets/images/icons/046-yoga mat.svg"
import kettlebell from "../../../assets/images/icons/047-kettlebell.svg"
import weights from "../../../assets/images/icons/048-weights.svg"
import resistance3 from "../../../assets/images/icons/049-resistance band.svg"
import wheel from "../../../assets/images/icons/050-wheel.svg"

const icons = {
  water, sport, skipping, buck, gymnastic, speedball, heart, pedometer, punching, smartwatch, traffic, sports, trainers, dumbbell, tank, sportbag, yoga, rope, stationary, muscle, measure, barbell, hand, diet, training, foam, plyo, training2, agility, agility2, calendar, energy, location, towel, step, chest, resistance, boxing, whistle, treadmill, basketball, swimming, football, stopwatch, resistance2, yoga2, kettlebell, weights, resistance3, wheel,
}

function useRoutine(routineId) {
  const { getRoutine, saveRoutine } = useContext(IdbContext);
  const [routine, setRoutine] = useState(null);
  useEffect( () => {
    if ( routineId ) {
      getRoutine(routineId).then( setRoutine )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routineId]);
  const updateIcon = (iconName) => {
    if ( !routine ) return;
    routine.iconName = iconName;
    saveRoutine( routine ).then( setRoutine );
  }
  return [routine, updateIcon];
}

export function IconEdit() {
  
}

export function IconEditor({updateIcon, icon, iconName, showEditor, setShowEditor}) {
  const iconNameArray = Object.keys(icons);
  
  const onClick = (name) => {
    updateIcon(name);
    setShowEditor(false);
  }

  return (
    <div className={`routineicon__editor ${showEditor ? "routineicon__editor--show" : ""}`}>
      { iconNameArray.map( name => (
        <span key={name} className={`routineicon__editorimg ${iconName === name ? "routineicon--active" : "" }`}>
          <img onClick={()=>onClick(name)} alt={name} src={icons[name]} />
        </span>
      ))}
    </div>
  )
}

export function Icon({routine, routineId, className="", allowEdit}) {
  if ( !routineId && routine ) {
    routineId = routine.id;
  }
  const [theRoutine, updateIcon] = useRoutine( routineId );
  const [showEditor, setShowEditor] = useState( false );
  
  let icon;
  if ( theRoutine && theRoutine.iconName ) {
    icon = icons[theRoutine.iconName];
  }

  if (!icon) {
    className += " routineicon--blank"
  }
  if ( allowEdit ) {
    className += " routineicon--allowedit"
  }


  return (
    <span className={`routineicon ${className}`}>
      { !icon && allowEdit ? (
        <button title="Add icon" className="routineicon__addicon" onClick={()=>setShowEditor(!showEditor)}></button> 
      ) : false  }
      { icon ? (
        <img onClick={()=>setShowEditor(!showEditor)} className="routineicon__img" alt={`${theRoutine.iconName} icon. Click to edit.`} src={icon} />
      ) : false }
      { allowEdit && theRoutine ? <IconEditor showEditor={showEditor} setShowEditor={setShowEditor} iconName={theRoutine.iconName} icon={icon} updateIcon={updateIcon} /> : false }
    </span>
  )
}
