import Quantity from "./Quantity";

export default class Weight extends Quantity {
  constructor(valueText, unitText) {
    super( valueText, unitText );
    this.blockType = "Weight";

    this.units = {
      "Kg": ["kg","kgs","kilo","kilos","kilograms","kilogram"],
      "Lb": ["lb","lbs","pound","pounds"]
    }
    this.parse();
  }
}