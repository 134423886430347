import React, { useContext } from 'react'
import { UserContext } from "../../contexts";
import {Loading} from "../";

function firstWord(str) {
  return str.trim().split(" ")[0];
}

function loggedIn(user,signOut) {
  return (
    <div className="page">
      <h1>Hi {firstWord(user.name)},</h1>
      <p>You're signed in!</p>
      <button onClick={()=>signOut()}>Sign Out</button>
    </div>
  )
}

function loggedOut() {
  return (
    <div className="page">
      <h1>Hi there!</h1>
      <h3>You're not currently signed in.</h3>
      <p><a href={`${ process.env.REACT_APP_BACKEND_HOST }/api/auth/google`}>Sign in or register</a>.</p>
    </div>
  )
}

export function Profile() {
  const {
    user, 
    status,
    StatusOptions,
    signOut,
  } = useContext(UserContext);
  if ( user && status === StatusOptions.logged_in ) return loggedIn(user,signOut);
  if ( status === StatusOptions.logged_out ) return loggedOut(user,signOut);
  return <Loading />;
}
