import Quantity from "./Quantity";

export default class Reps extends Quantity { 
  constructor(valueText, unitText) {
    super( valueText, unitText );
    this.blockType = "Reps";

    this.units = {
      "Reps": ["r","rep","reps","repetition","repetitions","times"],
    }
    this.parse();
  }
}
