import React from 'react'
import logo from "../../assets/images/color_logo_compact.svg";

export function NavPresenter({sidebarOpen, navItems, currentPath, toggleSidebar, goBack }) {
  let burgerClass = "burger";
  if ( sidebarOpen || goBack ) burgerClass += " burger--back";
  let sidebarClass = "nav__sidebar";
  if( sidebarOpen && !goBack ) sidebarClass += " nav__sidebar--open";
  let onClick = () => {
    if ( goBack ) return goBack();
    return toggleSidebar();
  }
  return (
      <header className="nav">
        <div className="nav__bar">
          <button onClick={onClick} className="nav__button">
            <span className="visually-hidden">Menu</span>
            <span className={burgerClass}>
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
          <img className="nav__logo" alt="Fit Friend Logo" src={logo} />
        </div>

        <nav className={sidebarClass}>
          { navItems.map( navItem => 
            navItem.toComponent(currentPath)
          )}
        </nav>
      </header>
  )
}

NavPresenter.propTypes = {

}
