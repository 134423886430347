/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { useLocation, withRouter } from "react-router-dom";
import "./nav.scss";
import { NavItem } from "./NavItem";
import { NavPresenter } from "./NavPresenter";


function NavInner({ history }) {
  const location = useLocation();
  const { pathname } = location;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  function toggleSidebar( forceSet ){
    if ( typeof forceSet === "boolean" ) {
      return setSidebarOpen( forceSet );
    }
    return setSidebarOpen( !sidebarOpen );
  }

  function onNavClick() {
    toggleSidebar(false);
  }
  
  const navItems = [
    new NavItem( "/", "Workouts", { onClick: onNavClick } ),
    new NavItem( "/about", "About", { onClick: onNavClick } ),
    new NavItem("./profile", "Profile", { onClick: onNavClick }),
  ] 

  let goBack;
  if ( pathname.indexOf("/history/") === 0 ) {
    // const id = pathname.substring(9);
    goBack = ()=>{
      history.push("/");
    }
  }
  
  return <NavPresenter 
    sidebarOpen={sidebarOpen} 
    navItems={navItems} 
    toggleSidebar={toggleSidebar} 
    currentPath={pathname} 
    goBack={goBack}/>
}

export const Nav = withRouter(NavInner);
