import React from 'react'

export function About() {
  return (
    <div className="page">
      <h1>Welcome to the Fit-Friend pre-alpha test</h1>
      <h2>What is it?</h2>
      {/* <p>If you've ever used a fitness tracking app, you'll know how easy it is to stop using them. The interface is inevitably clunky, they often can't handle some the specifics of your routine, and data-entry is time consuming. There are a lot of dissatissified customers; over half of fitness tracking app users stop using them within the first 6 months, with many gym-goes resorting to their phone's notes app.</p> */}
      <p>Fit Friend is a text editor, just like your notes app, which understands workouts. It can understand weights, times, distances, sets, reps, drop-sets, cooldown times and going to failure.</p>
      <p>It can extract this information to show you your progress and achievements.</p>
      <p>Once the language is finalised, a language explanation will be available on this page.</p>
      <h2>What feedback is useful?</h2>
      <p>The goal of this test is to refine the digital shorthand notation for workouts of all types. Please try it out and let Henry know how you get on, either send him a chat or <a href="mailto:henry@bluelyon.tech">an email</a>.</p>
      <p>Your feedback is greatly appreciated! Data entered into the site is not recorded for any purposes and will not leave your browser.</p>
      <p>Please also feel free to comment on the roadmap below, and features you feel would/would not be useful.</p>
      <h2>What is the Roadmap?</h2>
      <p>Here is a rough summary of the core features that are planned for the first version:</p>
      <ul>
        <li>Saving workouts</li>
        <li>Scheduling workouts</li>
        <li>Login / logout controls</li>
        <li>Background sync and backup for logged-in users</li>
        <li>Analysis of workouts</li>
      </ul>
      <p>Social Features planned for version 2</p>
      <ul>
        <li>Share programs with friends</li>
        <li>Send motivational push notifications to friends</li>
      </ul>
      <p>Features under consideration:</p>
      <ul>
        <li>Content area (articles, first-aid information, exercise demos)</li>
        <li>Run programs</li>
        <li>Share programs</li>
        <li>Body metrics (weight, BMI, body fat %)</li>
        <li>Nutrition information</li>
        <li>Nutrition information</li>
        <li>Dark mode</li>
        <li>Onboarding carousel</li>
      </ul>
      <h2>Attribution</h2>
      <p>App concept by Henry and Alistair McIntosh.</p>
      <p>Built by Henry McIntosh.</p>
      <p>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a></p>
    </div>
  )
}
