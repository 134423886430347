import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import { UserContextProvider, InstallContextProvider, IdbContextProvider } from "./contexts";
import { New, Nav, Error, About, Single, Profile, Home } from "./components"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

function App() {
  const theme = createMuiTheme({
    typography: {
      // Tell Material-UI what's the font-size on the html element is.
      htmlFontSize: 20,
    },
  });
  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <InstallContextProvider>
        <IdbContextProvider>
          <UserContextProvider>
            <div className="app">
              <Nav />
              <div className="app__main">
                <Switch>
                  <Route exact path="/" component={Home}/>
                  <Route path="/insights" component={Home}/>
                  <Route exact path="/new" component={New}/>
                  <Route exact path="/new/:routineId" component={New}/>
                  <Route exact path="/about" component={About}/>
                  <Route exact path="/profile" component={Profile}/>
                  <Route path="/history/:workoutId" component={Single}/>
                  <Route path="/" component={Error}/>
                </Switch>
              </div>
            </div>
          </UserContextProvider>
        </IdbContextProvider>
      </InstallContextProvider>
    </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;
